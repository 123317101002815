<script setup>
import Input from "~/components/atoms/Input.vue";
import PaperClipIcon from "~/components/icons/PaperClipIcon.vue";
import CrossIcon from "~/components/icons/CrossIcon.vue";

const emit = defineEmits(['update:modelValue', 'focusout', 'focusin', 'filesChanged'])

const props = defineProps({
  modelValue: String,
  name: String,
  disabled: {
    type: Boolean,
    default: false
  },
  options: Array,
})

const selectActive = ref(false);

const input = ref(null);
const files = ref([]);
const chunk = ref(Math.random());

const handleFileChange = (event) => {
  const selectedFiles = Array.from(event.target.files);
  files.value = files.value.concat(selectedFiles);

  triggerChange()

  chunk.value = Math.random();
}

const triggerChange = () => {
  emit('filesChanged', {
    name: props.name, files: files.value
  });
}

</script>

<template>
  <div :key="chunk" @click="input.click()" class="row full-width file-container clickable"
       :class="{active:!disabled && selectActive}">
    <PaperClipIcon/>
<!--    <input accept="image/*" multiple @change="handleFileChange" ref="input" type="file" :name="name"/>-->

    <input accept="image/*, video/*" multiple @change="handleFileChange" ref="input" type="file" :name="name"/>
  </div>
  <div v-if="files.length" class="file-list g-8">
    <p v-for="(file,index) in files" :key="file">
      <span>{{ file.name }}</span>
      <CrossIcon class="close clickable" @click="files.splice(index,1); chunk=Math.random(); triggerChange()"/>
    </p>
  </div>
</template>
<style scoped lang="scss">
@import 'assets/scss/variables.scss';

input {
  pointer-events: none;
  display: none;
}

.file-container {
  min-height: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

svg {
  transform: rotate(90deg);
  stroke: $black;
  margin-top: -8px;
}

.file-list {
  margin-top: 20px;
}

.close {
  transform: translateY(3px);
  margin-left: 5px;
}
</style>
